/* eslint-disable import/prefer-default-export */
import isNumber from 'lodash/isNumber';

/**
 * Will append numbers with 'px'.
 * For props where we want to accept either numbers, percentages, em, etc.
 */
export function toPixels(number) {
  if (isNumber(number)) {
    return `${number}px`;
  }

  return number;
}
