import React, {
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import useKeyListener from '../../hooks/useKeyListener';
import {
  ContentWrapper,
  ModalBody,
  OverlayWrapper,
} from './styles';

function Modal({
  children,
  headerChildren,
  closeButton: CloseButton,
  withPadding,
  onClose,
  closeOnOverlayClick,
  size,
  width,
  height,
  ...rest
}) {
  const overlayRef = useRef(null);

  const handleOverlayClick = useCallback((e) => {
    if (closeOnOverlayClick && e.target === overlayRef.current) {
      onClose(e);
    }
  }, [onClose, closeOnOverlayClick, overlayRef]);

  // 'Esc' for IE, of course
  useKeyListener(['Escape', 'Esc'], onClose);

  return (
    <OverlayWrapper
      {...rest}
      ref={overlayRef}
      onClick={handleOverlayClick}
    >
      <ModalBody size={size} width={width} height={height}>
        {headerChildren}
        <ContentWrapper withPadding={withPadding}>
          {children}
        </ContentWrapper>
      </ModalBody>
    </OverlayWrapper>
  );
}

Modal.propTypes = {
  onClose: PropTypes.func,
  closeOnOverlayClick: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  headerChildren: PropTypes.node,
  closeButton: PropTypes.func,
  withPadding: PropTypes.bool,
  /** If 'custom', won't apply any size styling */
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'custom']),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Modal.defaultProps = {
  onClose: noop,
  closeOnOverlayClick: true,
  className: '',
  children: null,
  headerChildren: null,
  closeButton: null,
  // TODO: default this to false.
  // this is the exact opposite of ModalWithHeader's default styling
  withPadding: true,
  size: 'md',
  width: undefined,
  height: undefined,
};

export default Modal;
