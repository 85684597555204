/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes, { object } from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { Typography } from '@one-thd/sui-atomic-components';

const InternalNotificationBanner = ({ details, isPreview }) => {
  if (_isEmpty(details)) {
    return null;
  }
  const headerText = `${details?.productName} - ${details?.isDraft ? 'Draft' : 'Preview'}`;
  return (
    <div className="sui-absolute sui-z-max sui-top-0 sui-left-0 sui-right-0 sui-bg-warning sui-p-8 sui-h-32">
      <Typography variant="h4" weight="display" height="loose">
        {headerText}
      </Typography>
      {isPreview && !details?.isDraft && (
        <Typography variant="body-lg" weight="regular" height="loose">
          Unable to Preview IDM Price and Media
        </Typography>
      )}
    </div>
  );
};

InternalNotificationBanner.propTypes = {
  details: object,
  isPreview: PropTypes.bool,
};

InternalNotificationBanner.defaultProps = {
  details: {
    productName: 'Draft',
  },
  isPreview: false,
};

export default InternalNotificationBanner;
