import React from 'react';
import PropTypes from 'prop-types';
import startsWith from 'lodash/startsWith';
import noop from 'lodash/noop';

const ListLink = ({
  children, className, href, onListLinkAnalyticsTrack,
}) => {
  const onListLinkButtonClick = () => {
    onListLinkAnalyticsTrack(children);
  };
  const listItem = (
    <a
      className={`list__link ${className}`}
      target={startsWith(href, '#') ? '_self' : '_blank'}
      href={href}
      onClick={() => onListLinkButtonClick()}
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
  return (listItem);
};

ListLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onListLinkAnalyticsTrack: PropTypes.func,
};

ListLink.defaultProps = {
  className: '',
  onListLinkAnalyticsTrack: noop,
};

export default ListLink;
