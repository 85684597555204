export const APP_MOUNT = 'APP_MOUNT';
export const UPDATE_THD_STORE_INFO = 'UPDATE_THD_STORE_INFO';
export const THD_PRODUCT_DETAILS_UPDATED = 'THD_PRODUCT_DETAILS_UPDATED';
export const UPDATE_GCC_PRODUCT_DETAILS = 'UPDATE_GCC_PRODUCT_DETAILS';
export const UPDATE_GCC_PRODUCT_DETAILS_SUCCESS = 'UPDATE_GCC_PRODUCT_DETAILS_SUCCESS';
export const STORE_DETAILS_UPDATED = 'STORE_DETAILS_UPDATED';
export const UPDATE_QUANTITY = 'UPDATE_QUANTITY';
export const UPDATE_SKUMAP = 'UPDATE_SKUMAP';
export const UPDATE_SKUMAP_STARTED = 'UPDATE_SKUMAP_STARTED';
export const UPDATE_SKUMAP_FAILED = 'UPDATE_SKUMAP_FAILED';
export const UPDATE_SKUMAP_FINISHED = 'UPDATE_SKUMAP_FINISHED';
export const UPDATE_SKUMAP_SPECIALORDER = 'UPDATE_SKUMAP_SPECIALORDER';
export const UPDATE_SKUMAP_CONFIGURATION_ERRORS = 'UPDATE_SKUMAP_CONFIGURATION_ERRORS';
export const GET_DELIVERY_OPTIONS_SUCCESS = 'GET_DELIVERY_OPTIONS_SUCCESS';
export const GET_DELIVERY_OPTIONS_FAILURE = 'GET_DELIVERY_OPTIONS_FAILURE';
export const UPDATE_ZIP = 'UPDATE_ZIP';
export const CART_SAVE_SUCCESS = 'CART_SAVE_SUCCESS';
export const CART_SAVE_STARTED = 'CART_SAVE_STARTED';
export const CART_RESET = 'CART_RESET';
export const CART_MODAL_HIDE = 'CART_MODAL_HIDE';
export const CART_SAVE_FAILED = 'CART_SAVE_FAILED';
export const GET_GALLERY_WITH_FALLBACKS = 'GET_GALLERY_FALLBACK';
export const UPDATE_GALLERY = 'UPDATE_GALLERY';
export const UPDATE_PRODUCT_GALLERY = 'UPDATE_PRODUCT_GALLERY';
export const STORE_SEARCH_SHOW = 'STORE_SEARCH_SHOW';
export const STORE_SEARCH_HIDE = 'STORE_SEARCH_HIDE';
export const SET_FREQUENTLY_BOUGHT_TOGETHER = 'SET_FREQUENTLY_BOUGHT_TOGETHER';
export const CLEAR_FREQUENTLY_BOUGHT_TOGETHER = 'CLEAR_FREQUENTLY_BOUGHT_TOGETHER';
export const FBT_ITEM_SELECTED = 'FBT_ITEM_SELECTED';
export const FBT_ITEM_UNSELECTED = 'FBT_ITEM_UNSELECTED';
export const CLEAR_FREQUENTLY_BOUGHT_TOGETHER_SELECTION = 'CLEAR_FREQUENTLY_BOUGHT_TOGETHER_SELECTION';
export const SHOW_FBT_MODAL = 'SHOW_FBT_MODAL';
export const HIDE_FBT_MODAL = 'HIDE_FBT_MODAL';
export const STORE_FBT_COUNT = 'STORE_FBT_COUNT';
export const SET_CONFIGURATION_ERROR = 'SET_CONFIGURATION_ERROR';
export const CLEAR_CONFIGURATION_ERROR = 'CLEAR_CONFIGURATION_ERROR';
export const UPDATE_PRODUCTINFO = 'UPDATE_PRODUCTINFO';
export const UPDATE_FULFILLMENTMETHOD = 'UPDATE_FULFILLMENTMETHOD';
export const UPDATE_PATH = 'UPDATE_PATH';
export const UPDATE_SO_DELIVERY_TIME = 'UPDATE_SO_DELIVERY_TIME';
export const SHOW_RIGHT_PANEL = 'SHOW_RIGHT_PANEL';
export const SHOW_VISUALIZER = 'SHOW_VISUALIZER';

export const THD_PRODUCT_CHOICE_SKU_AVAILABILITY = 'THD_PRODUCT_CHOICE_SKU_AVAILABILITY';
export const THD_PRODUCT_CHOICE_SKU_AVAILABILITY_SUCCESS = 'THD_PRODUCT_CHOICE_SKU_AVAILABILITY_SUCCESS';
export const THD_PRODUCT_AVAILABILITY_INIT = 'THD_PRODUCT_AVAILABILITY_INIT';
export const THD_PRODUCT_AVAILABILITY_DEFAULT = 'THD_PRODUCT_AVAILABILITY_DEFAULT';
export const PRE_AUTO_SELECT_CHOICES = 'PRE_AUTO_SELECT_CHOICES';
export const UPDATE_PRICING = 'UPDATE_PRICING';
export const CLEAR_PRICING = 'CLEAR_PRICING';

export const GET_PRICING = 'GET_PRICING';
export const PRICING_AVAILABLE = 'PRICING_AVAILABLE';
// debugging/testing actions
export const GET_PRICING_FROM_THD = 'GET_PRICING_FROM_THD';
export const GET_PRICING_SO = 'GET_PRICING_SO';
export const GET_PRICING_MSRP = 'GET_PRICING_MSRP';
export const GET_PRICING_ERROR = 'GET_PRICING_ERROR';

export const FAV_SAVE_STARTED = 'FAV_SAVE_STARTED';
export const FAV_SAVE_INIT = 'FAV_SAVE_INIT';
export const FAV_SAVE_DISPLAY = 'FAV_SAVE_DISPLAY';
export const ADD_ITEM_TO_FAVOURITES = 'ADD_ITEM_TO_FAVOURITES';
export const UPDATE_MEMBER_FAVOURITE_LIST = 'UPDATE_MEMBER_FAVOURITE_LIST';
export const HIDE_FAVOURITE_MODAL = 'HIDE_FAVOURITE_MODAL';
export const FAV_SAVE_COMPLETE = 'FAV_SAVE_COMPLETE';
export const FAV_INIT_MEMBER = 'FAV_INIT_MEMBER';

export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const SET_INITIAL_CONFIG = 'SET_INITIAL_CONFIG';
export const UPDATE_ISCOPY = 'UPDATE_ISCOPY';
export const UPDATE_CONFIGURATOR_MODE = 'UPDATE_CONFIGURATOR_MODE';
export const UPDATE_LINEITEM = 'UPDATE_LINEITEM';
export const UPDATE_LINEITEM_GUID = 'UPDATE_LINEITEM_GUID';

export const GET_EARLIEST_DELIVERY_OPTIONS_SUCCESS = 'GET_EARLIEST_DELIVERY_OPTIONS_SUCCESS';
export const GET_EARLIEST_DELIVERY_OPTIONS_FAILURE = 'GET_EARLIEST_DELIVERY_OPTIONS_FAILURE';
export const UPDATE_EARLIEST_DELIVERY_TIME = 'UPDATE_EARLIEST_DELIVERY_TIME';
export const GET_EARLIEST_DELIVERY_OPTIONS = 'GET_EARLIEST_DELIVERY_OPTIONS';
export const GET_EARLIEST_DELIVERY_OPTIONS_STARTED = 'GET_EARLIEST_DELIVERY_OPTIONS_STARTED';
export const SET_ZIP = 'SET_ZIP';
export const CHECK_AVAILABILITY = 'CHECK_AVAILABILITY';
export const SET_SHOW_ZIPCODE_AND_CHECK = 'SET_SHOW_ZIPCODE_AND_CHECK';

export const SEND_ATC_FROM_BUYBOX_EVENT_TO_ANALYTICS = 'SEND_ATC_FROM_BUYBOX_EVENT_TO_ANALYTICS';
export const SEND_PAYMENT_ESTIMATOR_EVENT_TO_ANALYTICS = 'SEND_PAYMENT_ESTIMATOR_EVENT_TO_ANALYTICS';
export const SEND_AVAILABILITY_MODAL_LOAD_EVENT_TO_ANALYTICS = 'SEND_AVAILABILITY_MODAL_LOAD_EVENT_TO_ANALYTICS';
export const SEND_CHECK_AVAILABILITY_EVENT_TO_ANALYTICS = 'SEND_CHECK_AVAILABILITY_EVENT_TO_ANALYTICS';
export const SET_IS_APP_LOAD_ANALYTICS_DONE = 'SET_IS_APP_LOAD_ANALYTICS_DONE';

export const REST_EARLIEST_DELIVERY = 'REST_EARLIEST_DELIVERY';
export const GET_EARLIEST_DELIVERY_FROM_MODAL = 'GET_EARLIEST_DELIVERY_FROM_MODAL';

export const UPDATE_CART_PRODUCT_GALLERY = 'UPDATE_CART_PRODUCT_GALLERY';
export const UPDATE_CART_PRODUCT_PRICING = 'UPDATE_CART_PRODUCT_PRICING';
export const UPDATE_CART_PRODUCT_CONFIGURATION = 'UPDATE_CART_PRODUCT_CONFIGURATION';
export const UPDATE_CART_PRODUCT = 'UPDATE_CART_PRODUCT';

export const CART_PRODUCT_CHANGED = 'CART_PRODUCT_CHANGED';

export const UPDATE_PAGE_META_LOADING = 'UPDATE_PAGE_META_LOADING';
export const GET_PAGE_META_OVERVIEW_SUCCESS = 'GET_PAGE_META_OVERVIEW_SUCCESS';
export const UPDATE_PAGE_META_OVERVIEW = 'UPDATE_PAGE_META_OVERVIEW';
export const UPDATE_DYNAMIC_TAB_TITLE = 'UPDATE_DYNAMIC_TAB_TITLE';

export const CHOICE_CHANGED_SUCCESS = 'CHOICE_CHANGED_SUCCESS';

export const PERSIST_CHOICES_ON_LOAD = 'PERSIST_CHOICES_ON_LOAD';
export const UPDATE_FULFILLMENT_OPTIONS = 'UPDATE_FULFILLMENT_OPTIONS';

export const CLEAR_PERSISTED_CHOICES = 'GCC_CONFIGURATOR/CLEAR_PERSISTED_CHOICES';

export const LOG_TO_NEW_RELIC = 'LOG_TO_NEW_RELIC';

export const UPDATE_THD_PRODUCT_DETAILS = 'UPDATE_THD_PRODUCT_DETAILS';
export const CONFIGURATOR_SET_HYDRATION_COMPLETE = '[Configurator] SET_HYDRATION_COMPLETE';