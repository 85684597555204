import { takeLatest, select } from 'redux-saga/effects';
import safeSaga from '../safeSaga';
import { UPDATE_CART_PRODUCT_CONFIGURATION } from '../../actions/actionTypes';
import { configSelector } from '../../selectors';
import { UPDATE_PRODUCT_DELIVERY } from '../../modules/decking-configurator/actions/deliveryActions';
import { LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE } from '../../actions/hydration/constants';

function* disableAddtoCart() {
  const config = yield select(configSelector);
  // CODE SMELL: config for non-decking | config.settings for decking
  if (config?.isDraftProduct || config?.settings?.isDraftProduct) {
    LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE, { configuratorUnavailable: true });
  }
}

export default function* draftSaga() {
  yield takeLatest([UPDATE_CART_PRODUCT_CONFIGURATION, UPDATE_PRODUCT_DELIVERY], safeSaga(disableAddtoCart));
}
