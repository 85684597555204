import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isString from 'lodash/isString';
import isFunction from 'lodash/isFunction';
import Modal, { ContentWrapper } from '../Modal';
import {
  Header,
  HeadingCol,
  CloseCol,
  CloseIcon,
} from './styles';

const ModalWithoutContentPadding = styled(Modal)`
  ${ContentWrapper} {
    padding: 0;
  }
`;

const ModalWithHeader = ({
  header,
  onClose,
  ...rest
}) => {
  let headerContent;
  if (isFunction(header)) {
    headerContent = header();
  } else if (isString(header)) {
    headerContent = <h2>{header}</h2>;
  } else {
    headerContent = header;
  }

  const head = (
    <Header
      bordered={Boolean(headerContent)}
      // for basic (string/empty) headers only
      centered={!headerContent || isString(header)}
    >
      <HeadingCol>
        {headerContent}
      </HeadingCol>
      <CloseCol>
        <CloseIcon onClick={onClose} />
      </CloseCol>
    </Header>
  );

  return (
    <ModalWithoutContentPadding
      headerChildren={head}
      onClose={onClose}
      {...rest}
    />
  );
};

ModalWithHeader.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  closeOnOverlayClick: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'custom']),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ModalWithHeader.defaultProps = {
  header: null,
  className: '',
  closeOnOverlayClick: true,
  size: 'md',
  width: null,
  height: null,
};

export default ModalWithHeader;
