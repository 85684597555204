import React from 'react';
import PropTypes from 'prop-types';
import defaultTo from 'lodash/defaultTo';

// THD's specs on icons and their sizes can be found here:
// https://homedepot.invisionapp.com/dsm/the-home-depot/homedepot-com/folder/icons/5b8555d5cd75c3001b43de48?version=5e7cfe84c65eb42db22dfccc
const sizeMultipliers = {
  sm: 1,
  md: 1.4,
  lg: 1.7,
};

/**
 * Returns a commponent that takes an optional `size` prop.
 * If width or height props are explicitly given, they win.
 *
 * smWidth and/or smHeight should typically be around 25px.
 */
const withSizePresets = (smWidth, smHeight) => (Component) => {
  const SizedSvg = ({
    size,
    width,
    height,
    ...rest
  }) => {
    const multiplier = sizeMultipliers[size] || sizeMultipliers.sm;
    const w = defaultTo(width, smWidth * multiplier);
    const h = defaultTo(height, smHeight * multiplier);

    return (
      <Component
        width={w}
        height={h}
        {...rest}
      />
    );
  };

  SizedSvg.propTypes = {
    size: PropTypes.oneOf([
      'sm',
      'md',
      'lg',
    ]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  SizedSvg.defaultProps = {
    size: 'sm',
    width: undefined,
    height: undefined,
  };

  SizedSvg.displayName = `SizedSvg(${Component.name})`;

  return SizedSvg;
};

export default withSizePresets;